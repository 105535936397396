html {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

body {
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
}
